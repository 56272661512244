export default {
    "app.siteName": "طوف و شوف",
    "app.language": "اللغة",
    "app.arabic": "العربية",
    "app.english": "الإنجليزية",
    // routes
    "app.notfound": "عفواً, الصفحة غير موجودة",
    "app.unAuthorized": "عفواً, غير مصرّح لك الدخول",
    "app.unAuthorizedMessage": "عفواً, أنت غير مصرّح لك",
    "app.dashboard": "لوحة التحكم",
    "app.languages": "اللغات",
    "app.languageSettings": "إعدادات اللغة",
    "app.resources": "المصادر",
    "app.audit": "بيانات السجل",
    "app.cities": "المدن",
    "app.city": "المدينة",
    "app.locations": "المواقع",
    "app.categories": "الفئات",
    "app.category": "الفئة",
    "app.serviceName": "أسم الخدمة",
    "app.serviceType": "نوع الخدمة",
    "app.servicesTypes": "أنوع الخدمات",
    "app.serviceOptions": "خيارات الخدمة",
    "app.serviceTimes": "أوقات الخدمة",
    "app.serviceTime": "وقت الخدمة",
    "app.servicePrices": "أسعار الخدمة",
    "app.serviceRatings": "تقييمات الخدمة",
    "app.services": "الخدمات",
    "app.service": "الخدمة",
    "app.servicesProviders": "مقدموا الخدمات",
    "app.providers": "مقدموا الخدمات",
    "app.serviceProvider": "مقدم الخدمة",
    "app.programProvider": "مقدم برنامج الخدمة",
    "app.provider": "مقدم خدمة",
    "app.serviceLocation": "موقع الخدمة",
    "app.reservations": "الحجوزات",
    "app.reservation": "الحجز",
    "app.reservationOptions": "خيارات الحجز",
    "app.reservationsList": "قائمة الحجوزات",
    "app.cancelRefund": "الملغاة و الإستحقاقات",
    "app.users": "المستخدمين",
    "app.usersManagement": "إدارة المستخدمين",
    "app.termsAndConditions": "الأحكام والشروط",
    "app.termsAndConditionsAr": "الأحكام والشروط بالعربية",
    "app.termsAndConditionsEn": "الأحكام والشروط بالإنجليزية",
    "app.subscribe": "الإشتراك",
    "app.subscriptions": "الاشتراكات",
    "app.myServices": "خدماتى",
    "app.myReservations": "حجوزاتى",
    "app.vacations": "العطلات",
    "app.vacation": "العطلة",
    "app.details": "التفاصيل",
    "app.list": "القائمة",
    "app.home": "الرئيسية",
    "app.new": "جديد",
    "app.userRole": "وظيفة المستخدم",
    "app.permissions": "الصلاحيات",
    "app.permission": "الصلاحية",
    "app.allPermissions": "كل الصلاحيات",
    "app.currentPermissions": "الصلاحيات الحالية",
    "app.contact": "التواصل",
    "app.contactDetails": "تفاصيل التواصل",
    "app.pagesManagement": "إدارة الصفحات",
    "app.testimonials": "الإقتباسات",
    "app.refundedAmount": "المبلغ المسترد",
    "app.programs": "البرامج",
    "app.program": "البرنامج",
    "app.programTimes": "أوقات البرنامج",
    "app.programOptions": "خيارات البرنامج",
    "app.programPrices": "أسعار البرنامج",
    "app.programType": "نوع البرنامج",
    "app.programRatings": "تقييمات البرنامج",
    "app.{value}": "{value}",
    // search
    "app.searchIn": "بحث فى",
    "app.search": "بحث",
    "app.erase": "تفريغ",
    // filter
    "app.reset": "إعادة تعين",
    // messages
    "app.messages.delete": "تم الإلغاء بنجاح",
    "app.messages.cancel": "تم التجاهل",
    "app.messages.cancel.warning": "هل تريد فعلا الإلغاء ؟",
    "app.messages.yes": "نعم",
    "app.messages.no": "لا",
    "app.messages.add": "تمت الإضافة بنجاح",
    "app.messages.edit": "تم التعديل بنجاح",
    "app.messages.change": "تم التغيير بنجاح",
    "app.messages.failed": "عملية غير ناجحة",
    "app.messages.success": "عملية ناجحة",
    "app.messages.upload": "تم الرفع بنجاح",
    "app.messages.download": "تم التحميل بنجاح",
    "app.messages.confirm": "تم التأكيد بنجاح",
    "app.messages.approve": "تم الموافقة بنجاح",
    "app.messages.reject": "تم الرفض بنجاح",
    "app.messages.finished": "تم الإنهاء بنجاح",
    "app.messages.createReservationFirst": "من فضلك قم بإنشاء الحجز أولاً",
    "app.messages.createProviderFirst": "من فضلك قم بإنشاء مقدم خدمة أولاً",
    "app.messages.createOrderFirst": "من فضلك قم بإنشاء الطلب أولاّ",
    "app.messages.createServiceFirst": "من فضلك قم بإنشاء الخدمة أولاً",
    "app.messages.createProgramFirst": "من فضلك قم بإنشاء برنامج أولاً",
    "app.messages.pageSizeChangedTo": "تم تغيير حجم الصفحة ل {value}",
    "app.messages.noValueWarning": "من فضلك اعط قيمة",
    "app.messages.deleteServiceWarning": "الخدمة تحتوى على بيانات تحتاج للألغاء أولا",
    "app.messages.refund.warning": "هل تريد فعلاً إعادة المستحقات ؟",
    "app.messages.refund.success": "تم الإستحقاق بنجاح",
    "app.messages.notAcceptedFileFormat": "صيغة غير مدعومة!",
    // various
    "app.options": "الخيارات",
    "app.settings": "الإعدادات",
    "app.sysSettings": "إعدادات النظام",
    "app.add": "إضافة",
    "app.edit": "تعديل",
    "app.delete": "إلغاء",
    "app.download": "تحميل",
    "app.upload": "رفع",
    "app.form.saveUpload": "حفظ الرفع",
    "app.all": "الكل",
    "app.notActivated": "غير مفعّل",
    "app.activated": "مفعّل",
    "app.user": "مستخدم",
    "app.manager": "مدير",
    "app.admin": "مشرف",
    "app.anonymousUser": "مستخدم مجهول",
    "app.rating": "التقييم",
    "app.ratings": "{rateCount, plural, =0 {لا يوجد تقييمات} one {{rateCount} تقييم} other {{rateCount} تقييمات}}",
    "app.gallery": "معرض الصور",
    "app.generalInfo": "بيانات عامة",
    "app.create": "إنشاء",
    "app.payments": "المدفوعات",
    "app.totalPayments": "إجمالى المدفوعات",
    'app.offer': 'عرض',
    "app.totalCost": "التكلفة الكلية",
    "app.totalTax": "الضريبة الكلية",
    "app.total": "الإجمالى",
    "app.transaction": "التحويل",
    "app.transactionCode": "كود التحويل",
    "app.transactionDate": "تاريخ التحويل",
    "app.transactionTime": "وقت التحويل",
    "app.confirmReservation": "تأكيد الحجز",
    "app.specifyLocation": "تحديد الموقع",
    "app.latitude": "خط العرض",
    "app.longitude": "خط الطول",
    "app.mapZoom": "سعة تكبير الخريطة",
    "app.img": "صورة",
    "app.status": "الحالة",
    "app.order": "الترتيب",
    "app.noFiles": "لا يوجد ملفات",
    "app.noData": "لا يوجد بيانات",
    "app.guest": "الزائر",
    "app.guestType": " نوع الزائر",
    "app.contract": "العقد",
    "app.contractDuration": "مدة العقد",
    "app.contractDurationAr": "مدة العقد بالعربية",
    "app.contractDurationEn": "مدة العقد بالإنجليزية",
    "app.contractType": "نوع العقد",
    "app.contractTypeAr": "نوع العقد بالعربية",
    "app.contractTypeEn": "نوع العقد بالإنجليزية",
    "app.commercialName": "الإسم التجارى",
    "app.commercialNameAr": "الإسم التجارى بالعربية",
    "app.commercialNameEn": "الإسم التجارى بالإنجليزية",
    "app.commercialRecord": "السجل التجارى",
    "app.commercialRecordAr": "السجل التجارى بالعربية",
    "app.commercialRecordEn": "السجل التجارى بالإنجليزية",
    "app.commercialCard": "البطاقه الضريبيه",
    "app.cardNumber": "رقم البطاقة",
    "app.cardEnd": "إنتهاء البطاقة",
    "app.card": "البطاقة",
    "app.licenses": "التراخيص",
    "app.license": "الترخيص",
    "app.tourismLicense": "رخصة السياحة",
    "app.tourismLicenseAr": "رخصة السياحة بالعربية",
    "app.tourismLicenseEn": "رخصة السياحة بالإنجليزية",
    "app.facilitationLicense": "رخصة التيسير",
    "app.children": "أطفال",
    "app.adults": "البالغين",
    "app.foreigners": "أجانب",
    "app.foreignersChildren": "أطفال أجانب",
    "app.egyptians": "مصريين",
    "app.egyptiansChildren": "أطفال مصريين",
    "app.culture": "رمز اللغة",
    "app.displayText": "أسم عرض النص",
    "app.orderNum": "رقم الترتيب",
    "app.value": "القيمة",
    "app.documents": "الملفات",
    "app.document": "الملف",
    "app.userId": "معرّف المستخدم",
    "app.rowId": "معرّف الصف",
    "app.tableName": "اسم الجدول",
    "app.copy": "نسخ",
    "app.canceledList": "قائمة الملغى",
    "app.refundedList": "قائمة المستوفى حقه",
    "app.refund": "إعادة المستحقات",
    "app.refunded": "إستوفى حقه",
    "app.title": "العنوان",
    "app.titleAr": "العنوان بالعربية",
    "app.titleEn": "العنوان بالإنجليزية",
    "app.nameAr": "الإسم بالعربية",
    "app.nameEn": "الإسم بالإنجليزية",
    "app.videoUrl": "رابط الفيديو",
    "app.descriptionAr": "الوصف بالعربية",
    "app.descriptionEn": "الوصف بالإنجليزية",
    "app.detailsAr": "التفاصيل بالعربية",
    "app.detailsEn": "التفاصيل بالإنجليزية",
    "app.textAr": "النص بالعربية",
    "app.textEn": "النص بالإنجليزية",
    "app.reason": "السبب",
    "app.showInSections": "أقسام العرض",
    "app.showInHome": "ظهور فى الرئيسية",
    "app.showInSlider": "ظهور فى العرض المتحرك",
    "app.publish": "نشر",
    "app.assigned": "تم التعيين",
    // form
    "app.form.filterByProvider": "تصفية بمقدم الخدمة",
    "app.form.filterByStatus": "تصفية بالحالة",
    "app.form.login.success": "تم الدخول بنجاح",
    "app.form.login.failed": "دخول غير ناجح",
    "app.form.login": "تسجيل الدخول",
    "app.form.logout": "تسجيل الخروج",
    "app.form.signIn": "تسجيل الدخول",
    "app.form.signOut": "تسجيل الخروج",
    "app.form.registration": "تسجيل",
    "app.form.register": "سجّل",
    "app.form.required": "هذا الحقل مطلوب",
    "app.form.name": "الأسم",
    "app.form.password": "كلمة المرور",
    "app.form.confirmPassword": "تأكيد كلمة المرور",
    "app.form.passwordNotIndentical": "كلمة المرور غير متطابقة",
    "app.form.passwordmusthasnumbers&letters": "كلمة المرور يجب ان تحتوى على ارقام و حروف",
    "app.form.passwordmustnotbelessthan": "كلمة المرور يجب ان لا تقل عن {value}",
    "app.form.couldBeLeftBlank": "ممكن تركها فارغة",
    "app.form.userRole": "صفة المستخدم",
    "app.form.userType": "نوع المستخدم",
    "app.form.userRole&Status": "صفة و حالة المستخدم",
    "app.form.male": "ذكر",
    "app.form.map": 'الموقع على الخريطة',
    "app.form.female": "أنثى",
    "app.form.email": "البريد الإلكترونى",
    "app.form.firstName": "الأسم الأول",
    "app.form.lastName": "الأسم الأخير",
    "app.form.userName": "أسم المستخدم",
    "app.form.fullName": "الأسم بالكامل",
    "app.form.age": "العمر",
    "app.form.nationality": "الجنسية",
    "app.form.gender": "النوع",
    "app.form.phoneNumber": "رقم الجوال",
    "app.form.address": "العنوان",
    "app.form.addressAr": "العنوان بالعربية",
    "app.form.addressEn": "العنوان بالإنجليزية",
    "app.form.description": "الوصف",
    "app.form.subject": "الموضوع",
    "app.form.price": "السعر",
    "app.form.prices": "الأسعار",
    "app.form.cost": "التكلفة",
    "app.form.sellPrice": "سعر البيع",
    "app.form.buyPrice": "سعر الشراء",
    "app.form.costTax": "ضريبة التكلفة",
    "app.form.sellTax": "ضريبة البيع",
    "app.form.mandatory": "إلزامى",
    "app.form.optional": "إختيارى",
    "app.form.group": "جماعى",
    "app.form.single": "فردى",
    "app.form.maxCount": "أكبر عدد",
    "app.form.minCount": "أقل عدد",
    "app.form.maxCapacity": "أكبر سعة",
    "app.form.minCapacity": "أقل سعة",
    "app.form.save": "حفظ",
    "app.form.cancel": "إلغاء",
    "app.form.finish": "إنهاء",
    "app.form.duration": "المدة",
    "app.form.time": "الوقت",
    "app.form.fromHour": "من الساعة",
    "app.form.toHour": "إلى الساعة",
    "app.form.fromDate": "من تاريخ",
    "app.form.toDate": "إلى تاريخ",
    "app.form.startDate": "تاريخ الإبتداء",
    "app.form.endDate": "تاريخ الإنتهاء",
    "app.form.date": "التاريخ",
    "app.form.timeOfDay": "فترة اليوم",
    "app.form.period": "الفترة",
    "app.form.reservationCount": "عدد الحجوزات",
    "app.form.reservationDate": "تاريخ الحجز",
    "app.form.notValidEmail": "إيميل غير صحيح",
    "app.form.notValidNumber": "رقم غير صحيح",
    "app.form.notes": "ملاحظات",
    "app.form.tableName": "اسم الجدول",
    "app.form.dataBefore": "البيانات قبل",
    "app.form.dataAfter": "البيانات بعد",
    "app.form.from": "من",
    "app.form.to": "إلى",
    "app.form.text": "النص",
    "app.form.offer": "تخفيض",
    // week
    "app.week.sun": "الأحد",
    "app.week.mon": "الأثنين",
    "app.week.tue": "الثلاثاء",
    "app.week.wed": "الأربعاء",
    "app.week.thu": "الخميس",
    "app.week.fri": "الجمعة",
    "app.week.sat": "السبت",
    // dayphase
    "app.day.morning": "صباحاً",
    "app.day.afternoon": "مساءاً",
    "app.day.night": "ليلاً",
    "app.day.allDay": "طوال اليوم",
    //payment status
    "app.payment.waiting": "قيد الانتظار",
    "app.payment.paid": "تم الدفع",
    "app.payment.pending": "قيد التنفيذ",
    "app.payment.finished": "تم الانتهاء",
    "app.payment.canceled": "تم الإلغاء",

    //addons,
    "app.ReservationOptionsPrice": "مجموع سعر خيارات الحجز",

    // createdByUserId: null
    // creationDate: null
    // description: "ايس تى"
    // descriptionEn: null
    // id: 1094
    // isMandatory: false
    // lastUpdateDate: null
    // lastUpdatedBy: null
    // maxCountToOrder: 50
    // price: 20
    // servicesId: 1144
    // title: "شاى"
    // titleEn: "Tea"
    // userId: null
    // res options 
    "app.resOptions.description":"الوصف",
    "app.resOptions.descriptionEn":"الوصف بالإنجيزية",
    "app.resOptions.price":"السعر",
    "app.resOptions.title":"الإسم",
    "app.resOptions.titleEn":"الإسم بالإنجليزية",

};
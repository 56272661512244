export default {
    "app.siteName": "ToufWShouf",
    "app.language": "Language",
    "app.arabic": "Arabic",
    "app.english": "English",
    // routes
    "app.notfound": "Sorry, the page you visited does not really exist",
    "app.unAuthorized": "Sorry, you are not authorized to access this page",
    "app.unAuthorizedMessage": "Sorry, you are not authorized",
    "app.dashboard": "Dashboard",
    "app.languageSettings": "Language Settings",
    "app.languages": "Languages",
    "app.resources": "Resources",
    "app.audit": "Audit",
    "app.cities": "Cities",
    "app.city": "City",
    "app.locations": "Locations",
    "app.categories": "Categories",
    "app.category": "Category",
    "app.serviceName": "Service Name",
    "app.serviceType": "Service Type",
    "app.servicesTypes": "Services Types",
    "app.serviceOptions": "Service Options",
    "app.serviceTimes": "Service Times",
    "app.serviceTime": "Service Time",
    "app.servicePrices": "Service Prices",
    "app.serviceRatings": "Service Ratings",
    "app.services": "Services",
    "app.service": "Service",
    "app.servicesProviders": "Services Providers",
    "app.providers": "Providers",
    "app.serviceProvider": "Service Provider",
    "app.programProvider": "Program Provider",
    "app.provider": "Provider",
    "app.serviceLocation": "Service Location",
    "app.reservations": "Reservations",
    "app.reservation": "Reservation",
    "app.reservationOptions": "Reservation Options",
    "app.reservationsList": "Reservations List",
    "app.cancelRefund": "Canceled & Refunded",
    "app.users": "Users",
    "app.usersManagement": "Users Management",
    "app.termsAndConditions": "Terms And Conditions",
    "app.termsAndConditionsAr": "Arabic Terms And Conditions",
    "app.termsAndConditionsEn": "English Terms And Conditions",
    "app.subscribe": "Subscribe",
    "app.subscriptions": "Subscriptions",
    "app.myReservations": "My Reservations",
    "app.vacations": "Vacations",
    "app.vacation": "Vacation",
    "app.details": "Details",
    "app.list": "List",
    "app.home": "Home",
    "app.new": "New",
    "app.userRole": "User Role",
    "app.permissions": "Permissions",
    "app.permission": "Permission",
    "app.allPermissions": "All Permissions",
    "app.currentPermissions": "Current Permissions",
    "app.contact": "Contact",
    "app.contactDetails": "Contact Details",
    "app.pagesManagement": "Pages Management",
    "app.testimonials": "Testimonials",
    "app.refundedAmount": "Refunded Amount",
    "app.programs": "Programs",
    "app.program": "Program",
    "app.programTimes": "Program Times",
    "app.programOptions": "Program Options",
    "app.programPrices": "Program Prices",
    "app.programRatings": "Program Ratings",
    "app.programType": "Program Type",
    "app.{value}": "{value}",
    // search
    "app.searchIn": "Search In",
    "app.search": "Search",
    "app.erase": "Erase",
    // filter
    "app.reset": "Reset",
    // messages
    "app.messages.delete": "Deleted Successfully",
    "app.messages.cancel": "Ignored",
    "app.messages.cancel.warning": "Do you really want to cancel ?",
    "app.messages.yes": "Yes",
    "app.messages.no": "No",
    "app.messages.add": "Added Successfully",
    "app.messages.edit": "Edited Successfully",
    "app.messages.change": "Changed Successfully",
    "app.messages.failed": "Failed Process",
    "app.messages.success": "Successful Process",
    "app.messages.upload": "Uploaded Successfully",
    "app.messages.download": "Downloaded Successfully",
    "app.messages.confirm": "Confirmed Successfully",
    "app.messages.approve": "Approved Successfully",
    "app.messages.reject": "Rejected Successfully",
    "app.messages.finished": "Finished Successfully",
    "app.messages.createReservationFirst": "Please, Create Reservation First",
    "app.messages.createProviderFirst": "Please, Create Provider First",
    "app.messages.createOrderFirst": "Please, Create Order First",
    "app.messages.createServiceFirst": "Please, Create Service First",
    "app.messages.createProgramFirst": "Please, Create Program First",
    "app.messages.pageSizeChangedTo": "Page Size Changed to {value}",
    "app.messages.noValueWarning": "No Value, Please, Make Sense!",
    "app.messages.deleteServiceWarning": "Service has some data need to be deleted first",
    "app.messages.refund.warning": "Do you really want to Refund ?",
    "app.messages.refund.success": "Refunded Successfully",
    "app.messages.notAcceptedFileFormat": "Not Supported File Format!",
    // various
    "app.options": "Options",
    "app.settings": "Settings",
    "app.sysSettings": "Sys Settings",
    "app.add": "Add",
    "app.edit": "Edit",
    "app.delete": "Delete",
    "app.download": "Download",
    "app.upload": "Upload",
    "app.all": "All",
    "app.notActivated": "Not Activated",
    "app.activated": "Activated",
    "app.user": "User",
    "app.manager": "Manager",
    "app.admin": "Admin",
    "app.anonymousUser": "Anonymous User",
    "app.rating": "Rating",
    "app.ratings": "{rateCount, plural, =0 {No Ratings} one {{rateCount} Rate} other {{rateCount} Ratings}}",
    "app.gallery": "Gallery",
    "app.generalInfo": "General Info",
    "app.create": "Create",
    "app.payments": "Payments",
    "app.totalPayments": "Total Payments",
    "app.totalCost": "Total Cost",
    "app.totalTax": "VAT",
    "app.total": "Total",
    "app.transaction": "Transaction",
    "app.transactionCode": "Transaction Code",
    "app.transactionDate": "Transaction Date",
    "app.transactionTime": "Transaction Time",
    "app.confirmReservation": "Confirm Reservation",
    "app.specifyLocation": "Specifying The Location",
    "app.latitude": "Latitude",
    "app.longitude": "Longitude",
    "app.mapZoom": "Map Zoom",
    "app.img": "Image",
    "app.status": "Status",
    "app.order": "Order",
    "app.noFiles": "No Files",
    "app.noData": "No Data",
    "app.guest": "Guest",
    "app.guestType": "Guest Type",
    "app.contract": "Contract",
    "app.contractDuration": "Contract Duration",
    "app.contractDurationAr": "Arabic Contract Duration",
    "app.contractDurationEn": "English Contract Duration",
    "app.contractType": "Contract Type",
    "app.contractTypeAr": "Arabic Contract Type",
    "app.contractTypeEn": "English Contract Type",
    "app.commercialName": "Commercial Name",
    "app.commercialNameAr": "Arabic Commercial Name",
    "app.commercialNameEn": "English Commercial Name",
    "app.commercialRecord": "Commercial Record",
    "app.commercialRecordAr": "Arabic Commercial Record",
    "app.commercialRecordEn": "English Commercial Record",
    "app.commercialCard": "Commercial Card",
    "app.cardNumber": "Card Number",
    "app.cardEnd": "Card End",
    "app.card": "Card",
    "app.licenses": "Licenses",
    "app.license": "License",
    "app.tourismLicense": "Tourism License",
    "app.tourismLicenseAr": "Arabic Tourism License",
    "app.tourismLicenseEn": "English Tourism License",
    "app.facilitationLicense": "Facilitation License",
    "app.children": "Children",
    "app.adults": "Adults",
    "app.foreigners": "Foreigners",
    "app.foreignersChildren": "Foreigners Children",
    "app.egyptians": "Egyptians",
    "app.egyptiansChildren": "Egyptians Children",
    "app.culture": "Culture",
    "app.displayText": "Display Text",
    "app.orderNum": "Order Number",
    "app.value": "Value",
    "app.documents": "Documents",
    "app.document": "Document",
    "app.userId": "User Id",
    "app.rowId": "Row Id",
    "app.tableName": "Table Name",
    "app.copy": "Copy",
    "app.canceledList": "Canceled List",
    "app.refundedList": "Refunded List",
    "app.refund": "Refund",
    "app.refunded": "Refunded",
    "app.title": "Title",
    "app.titleAr": "Arabic Title",
    "app.titleEn": "English Title",
    "app.nameAr": "Arabic Name",
    "app.nameEn": "English Name",
    "app.descriptionAr": "Arabic Description",
    "app.descriptionEn": "English Description",
    "app.detailsAr": "Arabic Details",
    "app.detailsEn": "English Details",
    "app.textAr": "Arabic Text",
    "app.textEn": "English Text",
    "app.reason": "Reason",
    "app.showInSections": "Show In Sections",
    "app.showInHome": "Show In Home",
    "app.showInSlider": "Show In Slider",
    "app.publish": "Publish",
    "app.assigned": "Assigned",
    // form
    "app.form.filterByProvider": "Filter By Provider",
    "app.form.filterByStatus": "Filter By Status",
    "app.form.login.success": "Logged in Successfully",
    "app.form.login.failed": "Login Failed",
    "app.form.login": "Login",
    "app.form.logout": "Logout",
    "app.form.signIn": "Sign In",
    "app.form.signOut": "Sign Out",
    "app.form.registration": "Registration",
    "app.form.register": "Register",
    "app.form.required": "Required",
    "app.form.name": "Name",
    "app.form.password": "Password",
    "app.form.confirmPassword": "Confirm Password",
    "app.form.passwordNotIndentical": "Password Not Indentical",
    "app.form.passwordmusthasnumbers&letters": "password must has numbers & letters",
    "app.form.passwordmustnotbelessthan": "password must not be less than {value}",
    "app.form.couldBeLeftBlank": "Could Be Left Blank",
    "app.form.userRole": "User Role",
    "app.form.userType": "User Type",
    "app.form.userRole&Status": "User Role & Status",
    "app.form.male": "Male",
    "app.form.female": "Female",
    "app.form.email": "Email",
    "app.form.firstName": "First Name",
    "app.form.lastName": "Last Name",
    "app.form.userName": "User Name",
    "app.form.fullName": "Full Name",
    "app.form.age": "Age",
    "app.form.nationality": "Nationality",
    "app.form.gender": "Gender",
    "app.form.phoneNumber": "Phone Number",
    "app.form.address": "Address",
    "app.form.addressAr": "Arabic Address",
    "app.form.addressEn": "English Address",
    "app.form.description": "Description",
    "app.form.subject": "Subject",
    "app.form.price": "Price",
    "app.form.prices": "Prices",
    "app.form.cost": "Cost",
    "app.form.sellPrice": "Sell Price",
    "app.form.buyPrice": "Buy Price",
    "app.form.costTax": "Cost Tax",
    "app.form.sellTax": "Sell Tax",
    "app.form.mandatory": "Mandatory",
    "app.form.optional": "Optional",
    "app.form.group": "Group",
    "app.form.single": "Single",
    "app.form.maxCount": "Max Count",
    "app.form.minCount": "Min Count",
    "app.form.maxCapacity": "Max Capacity",
    "app.form.minCapacity": "Min Capacity",
    "app.form.save": "Save",
    "app.form.saveUpload": "Save Upload",
    "app.form.cancel": "Cancel",
    "app.form.finish": "Finish",
    "app.form.duration": "Duration",
    "app.form.time": "Time",
    "app.form.fromHour": "From Hour",
    "app.form.toHour": "To Hour",
    "app.form.fromDate": "From Date",
    "app.form.toDate": "To Date",
    "app.form.startDate": "Start Date",
    "app.form.endDate": "End Date",
    "app.form.date": "Date",
    "app.form.timeOfDay": "Time Of Day",
    "app.form.period": "Period",
    "app.form.reservationCount": "Reservation Count",
    "app.form.reservationDate": "Reservation Date",
    "app.form.notValidEmail": "Not a Valid Email",
    "app.form.notValidNumber": "Not a Valid Number",
    "app.form.notes": "Notes",
    "app.form.tableName": "Table Name",
    "app.form.dataBefore": "Data Before",
    "app.form.dataAfter": "Data After",
    "app.form.from": "From",
    "app.form.to": "To",
    "app.form.text": "Text",
    "app.form.offer": "Offer",
    // week
    "app.week.sun": "SunDay",
    "app.week.mon": "MonDay",
    "app.week.tue": "TuesDay",
    "app.week.wed": "Wednesday",
    "app.week.thu": "Thursday",
    "app.week.fri": "FriDay",
    "app.week.sat": "SaturDay",
    // dayphase
    "app.day.morning": "Morning",
    "app.day.afternoon": "Afternoon",
    "app.day.night": "Night",
    "app.day.allDay": "All Day",
    //payment status
    "app.payment.waiting": "Waiting",
    "app.payment.paid": "Paid",
    "app.payment.pending": "Pending",
    "app.payment.finished": "Finished",
    "app.payment.canceled": "Canceled",
};